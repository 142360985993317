"use client";

import React, { useEffect } from "react";
import { usePathname, useSearchParams } from "next/navigation";
import { SignInProvider } from "@/components/SignIn/SignInProvider";
import { env } from "@/env.mjs";
import { AnalyticsProvider } from "@/utils/AnalyticsProvider";
import { useFbPageview } from "@/utils/fpixel";
import { type SupportedLanguage } from "@/utils/i18n";
import { TrpcProvider } from "@/utils/TrpcProvider";
import Hotjar from "@hotjar/browser";
import { type Session } from "next-auth";
import { SessionProvider } from "next-auth/react";
import { AppProgressBar as ProgressBar } from "next-nprogress-bar";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { LocaleProvider } from "./[lang]/LocaleProvider";
let ko: {
  init: (key: string) => void;
  identify: (email?: string, properties?: Record<string, string>) => void;
};
if (typeof window !== "undefined" && env.NEXT_PUBLIC_POSTHOG_KEY && env.NEXT_PUBLIC_POSTHOG_HOST) {
  posthog.init(env.NEXT_PUBLIC_POSTHOG_KEY, {
    api_host: env.NEXT_PUBLIC_POSTHOG_HOST,
    persistence: "localStorage+cookie"
  });
}
if (typeof window !== "undefined" && env.NEXT_PUBLIC_KOALA_PUBLIC_KEY) {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  ko = require("@getkoala/react").default;
  // Load the Koala SDK immediately
  ko.init(env.NEXT_PUBLIC_KOALA_PUBLIC_KEY);
}
if (typeof window !== "undefined" && env.NEXT_PUBLIC_HOTJAR_SITE_ID && Number.isInteger(Number(env.NEXT_PUBLIC_HOTJAR_SITE_ID))) {
  Hotjar.init(Number(env.NEXT_PUBLIC_HOTJAR_SITE_ID), 6);
}
export function PostHogPageview() {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  // Track pageviews
  useEffect(() => {
    if (pathname) {
      let url = window.origin + pathname;
      if (searchParams?.toString()) {
        url = url + `?${searchParams?.toString()}`;
      }
      if (posthog.__loaded) posthog.capture("$pageview", {
        $current_url: url,
        $pathname: pathname
      });
    }
  }, [pathname, searchParams]);
  useFbPageview();
  return <></>;
}
export function Providers(props: {
  children: React.ReactNode;
  serverSession?: Session | null;
  language: SupportedLanguage;
}) {
  useEffect(() => {
    if (ko) {
      ko.identify(props.serverSession?.user?.email, {
        name: props.serverSession?.user.firstName + " " + props.serverSession?.user.lastName
      });
    }
    if (posthog.__loaded) posthog.identify(props.serverSession?.user?.id, {
      email: props.serverSession?.user.email,
      intercomId: props.serverSession?.user.intercomId,
      name: props.serverSession?.user.firstName + " " + props.serverSession?.user.lastName
    });
    if (Hotjar.isReady() && props.serverSession?.user?.id) {
      Hotjar.identify(props.serverSession?.user?.id, {
        email: props.serverSession?.user.email,
        name: props.serverSession?.user.firstName + " " + props.serverSession?.user.lastName
      });
    }
    posthog.setPersonPropertiesForFlags({
      email: props.serverSession?.user.email
    });
  }, [props.serverSession?.user]);
  return <LocaleProvider language={props.language} data-sentry-element="LocaleProvider" data-sentry-component="Providers" data-sentry-source-file="Providers.tsx">
      <SessionProvider refetchOnWindowFocus={false} session={props.serverSession} data-sentry-element="SessionProvider" data-sentry-source-file="Providers.tsx">
        <TrpcProvider data-sentry-element="TrpcProvider" data-sentry-source-file="Providers.tsx">
          <SignInProvider data-sentry-element="SignInProvider" data-sentry-source-file="Providers.tsx">
            <AnalyticsProvider data-sentry-element="AnalyticsProvider" data-sentry-source-file="Providers.tsx">
              <PostHogProvider client={posthog} data-sentry-element="PostHogProvider" data-sentry-source-file="Providers.tsx">
                <main>{props.children}</main>
                <ProgressBar height="4px" options={{
                showSpinner: false
              }} shallowRouting data-sentry-element="ProgressBar" data-sentry-source-file="Providers.tsx" />
              </PostHogProvider>
            </AnalyticsProvider>
          </SignInProvider>
        </TrpcProvider>
      </SessionProvider>
    </LocaleProvider>;
}