import { useCallback, useEffect, useState } from "react";

export const localStorageKeys = {
  vouchTutorialOpen: "hasVouchedBefore", // legacy. Only used to check if we should display tutorial or not
  vouchDefaultValues: (listingId: string) => `vouchForm_${listingId}`,
  vouchAutoSaveId: (listingId: string) => `vouchAutoSave_${listingId}`,
};

export default function useLocalStorage<T>(
  key: string,
  initialValue: T,
  opts?: {
    setInitialValueIfNotPresent?: boolean;
  },
): [T, (newVal: T) => void, boolean, () => void] {
  const [storedValue, setStoredValue] = useState<T>(() => {
    if (typeof window === "undefined") return initialValue;
    try {
      const item = window.localStorage.getItem(key);

      if (!item && opts?.setInitialValueIfNotPresent) {
        window.localStorage.setItem(key, JSON.stringify(initialValue));
        return initialValue;
      }

      return item ? (JSON.parse(item) as T) : initialValue;
    } catch (error) {
      console.error(error);
      return initialValue;
    }
  });

  const [isLoading, setIsLoading] = useState(true);

  // ? Needed? Might be needed to avoid hydration errors
  useEffect(() => {
    setIsLoading(false);
  }, []); // Load only once on initial render

  const setValue = useCallback(
    (newValue: T) => {
      setStoredValue(newValue);
      try {
        if (typeof window !== "undefined") {
          window.localStorage.setItem(key, JSON.stringify(newValue));
        }
      } catch (error) {
        console.error(`Error setting localStorage key "${key}":`, error);
      }
    },
    [key],
  );

  const removeValue = useCallback(() => {
    try {
      window.localStorage.removeItem(key);
    } catch (error) {
      console.error(`Failed to remove localStorage key "${key}":`, error);
    }
  }, [key]);

  return [storedValue, setValue, isLoading, removeValue];
}
