"use client";

import { useEffect } from "react";
import { usePathname } from "next/navigation";
import { env } from "@/env.mjs";
import { useIsClient } from "@/hooks/useIsClient";
import { v4 } from "uuid";

export const FB_PIXEL_ID = env.NEXT_PUBLIC_FB_PIXEL_ID;

declare global {
  interface Window {
    fbq: (...args: unknown[]) => void;
  }
}

export const pageview = () => {
  if (env.NEXT_PUBLIC_FB_PIXEL_ID) {
    window.fbq("track", "PageView");
  }
};

// https://developers.facebook.com/docs/facebook-pixel/advanced/
export const event = (name: string, options = {}) => {
  if (FB_PIXEL_ID) {
    window.fbq("track", name, options);
  }
};

export const useFbPageview = () => {
  const pathname = usePathname();

  const isClient = useIsClient();

  useEffect(() => {
    if (pathname) {
      if (env.NEXT_PUBLIC_FB_PIXEL_ID && isClient) {
        fbEvent({
          eventName: "PageView",
          enableStandardPixel: true,
        });
      }
    }
  }, [pathname, isClient]);
};

type FBEvent = {
  eventName: string;
  eventId?: string;
  emails?: Array<string> | null;
  phones?: Array<string> | null;
  firstName?: string;
  lastName?: string;
  country?: string;
  city?: string;
  zipCode?: string;
  products?: {
    sku: string;
    quantity: number;
  }[];
  value?: number;
  currency?: string;
  enableStandardPixel?: boolean;
  testEventCode?: string;
};

export const fbEvent = (event: FBEvent): void => {
  const eventId = event.eventId ? event.eventId : v4();

  if (event.enableStandardPixel) {
    const clientSidePayload = {
      ...(event?.products &&
        event.products.length > 0 && {
          content_type: "product",
          contents: event.products.map((product) => ({
            id: product.sku,
            quantity: product.quantity,
          })),
        }),
      ...(event.value && { value: event.value }),
      ...(event.currency && { currency: event.currency }),
    };

    window.fbq("track", event.eventName, clientSidePayload, { eventID: eventId });
  }

  setTimeout(() => {
    const serverSidePayload = JSON.stringify({
      eventName: event.eventName,
      eventId,
      emails: event.emails,
      phones: event.phones,
      firstName: event.firstName,
      lastName: event.lastName,
      country: event.country,
      city: event.city,
      zipCode: event.zipCode,
      products: event.products,
      value: event.value,
      currency: event.currency,
      userAgent: navigator.userAgent,
      sourceUrl: window.location.href,
      testEventCode: event.testEventCode,
    });

    void fetch("/api/fb-events", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: serverSidePayload,
    });
  }, 250);
};
